import React, { useState, useEffect } from 'react';
import { formatCurrency, useResponsiveState } from '../../../Utility/HelperFunctions';
import "./PaymentDetails.scss"
import Globe from '../../../Components/SVG/Globe';
import _ from 'lodash';
import { MAIN_CONFIG } from '../../../../config/main'


/**
 * Application form section
 *
 * @param {object} props
 * @param {'references' | ''} props.type
 * @param {string} props.title
 * @param {object[]} props.questions
 * @param {string[]} props.references
 * @param {boolean} props.isLast
 *
 * @returns {ReactElement}
 */

export default ({
  title = '',
  subTitle = '',
  paymentPageInfo,
  application,
  buttonSecondaryColor,
  ...props
}) => {
  const { isMobileScreen } = useResponsiveState();

  return (
    <div className={`stripe-payment-summery-page ${!isMobileScreen && 'stripe-payment-detail-page'}`}>
    {paymentPageInfo &&
      <>
        <div className="strope-payment-gateway-section-heading-container" >
          <Globe color={buttonSecondaryColor || MAIN_CONFIG.COLORS.fillGreen }/>
          <h1 className="section-heading" style={{ 'color': buttonSecondaryColor || MAIN_CONFIG.COLORS.fillGreen }}> Payment Details</h1>
        </div>


        <table className="stripe-payment-detail-tables">
          <tr>
            <td>
              <h2 className="text-secondary">Name</h2>
              <p >{_.get(paymentPageInfo, 'name__c', '')}</p>
            </td>
            <td>
              <h2 className="text-secondary">Last Digit</h2>
              <p >{_.get(paymentPageInfo, 'last4__c', '')}</p>
            </td>
          </tr>

          <tr>
            <td>
              <h2 className="text-secondary">Expiration Year</h2>
              <p >{_.get(paymentPageInfo, 'exp_year__c', '')}</p>
            </td>
            <td>
              <h2 className="text-secondary">Expiration Month</h2>
              <p >{_.get(paymentPageInfo, 'exp_month__c', '')}</p>
            </td>
          </tr>

          <tr>
            <td>
              {
                paymentPageInfo.stripe_plan_id__c ? null : (
                  <div>
                    <h2 className="text-secondary">Amount</h2>
                    <p >{formatCurrency(paymentPageInfo.amount__c)}</p>
                  </div>
                )
              }
            </td>
            <td>
              <h2 className="text-secondary">Processing Amount</h2>
              {!(application.processing_amount || paymentPageInfo.processing_fee_amount__c) ? null : (
                <p >{application.processing_amount ? formatCurrency(application.processing_amount) : formatCurrency(paymentPageInfo.processing_fee_amount__c)}</p>
              )}
            </td>
          </tr>

          {
            !paymentPageInfo.stripe_plan_id__c ? null : (
              <>
                <tr>
                  <td>
                    <h2 className="text-secondary">Amount</h2>
                    <p >$ {formatCurrency(paymentPageInfo.amount__c || 0)}</p>
                  </td>
                  <td>
                    <h2 className="text-secondary">Perpetual</h2>
                    <p >{paymentPageInfo.perpetual__c ? "Yes" : "No"}</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <h2 className="text-secondary">Frequency</h2>
                    <p >{paymentPageInfo.frequency__c}</p>
                  </td>
                  <td>
                    <h2 className="text-secondary">Number of Payments</h2>
                    <p >{paymentPageInfo.no_of_payments__c}</p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <h2 className="text-secondary">First Payment Date</h2>
                    <p >{paymentPageInfo.first_payment_date__c}</p>
                  </td>
                  <td>
                    <h2 className="text-secondary">Include Processing Fees</h2>
                    <p >{paymentPageInfo.include_processing_fee__c ? 'Yes' : 'No'}</p>
                  </td>
                </tr>
              </>
            )
          }

        </table>
      </>
    }
    </div>
  )
}