import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

export default ({ id, className, color = MAIN_CONFIG.COLORS.fillGreen, width = "200", height = "200", ariaLabel = "Preview", ariaLabelDescription = "Preview" }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
            <title>{ariaLabel}</title>
            <desc>{ariaLabelDescription}</desc>
            <defs>
                <clipPath id="clip-preview">
                    <rect width="200" height="200" />
                </clipPath>
            </defs>
            <g id="preview" clip-path="url(#clip-preview)">
                <g id="Group_9661" data-name="Group 9661" transform="translate(1323.919 -7135.001)">
                    <g id="noun-see-588449" transform="translate(-1313.919 7172)">
                        <path id="Path_57351" data-name="Path 57351" d="M162.19,85.531c-48.828,0-90,38.694-90,63s41.172,63,90,63,90-38.694,90-63-41.172-63-90-63Zm0,108c-42.131,0-72-34.123-72-45s29.87-45,72-45c40.074,0,72,32.315,72,45C234.191,159.41,204.32,193.534,162.19,193.534Z" transform="translate(-72.19 -85.531)" fill={color} />
                        <path id="Path_57352" data-name="Path 57352" d="M293.761,196.66a27.105,27.105,0,1,0,27.105,27.105A27.1,27.1,0,0,0,293.761,196.66Z" transform="translate(-203.761 -160.763)" fill={color} />
                    </g>
                </g>
            </g>
        </svg>
    );
}


