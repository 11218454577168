import React, { useState, useEffect } from 'react';
import Field from '../../Components/Field/Field';
import SpaceInvader from '../../Components/SVG/SpaceInvader'
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import CTAButton from '../../Components/CTAButton/CTAButton';
import { MAIN_CONFIG, dataTypeDoc, dataTypeJoin } from '../../../config/main';
import './form.scss';
import { CreateOptions } from '../../Utility/SelectListLoader';
import { getQuestionDataType } from '../../Utility/RecommendationDataTypeMapping';
import { parseHtml, formatAnswerOptions, formattedAnswer, validateEmailRegex } from '../../Utility/HelperFunctions';
import { getAnswer, getDocumentObject, applicantValidation, onChangeAnswer, findLabel, allowApplicantUploadsAfterDeadlineFunction, blankSpaceCheck, checkFieldLength, handleKeyDown } from '../../Utility/ApplicationFormUtility';
import isEmpty from 'lodash/isEmpty';
import FlashMessage from '../../Components/FlashMessage/Message';
import { ToastContainer, toast } from 'react-toastify';
import cookies from 'next-cookies';
import { cloneDeep } from 'lodash';



export default ({
	handleClose = () => { },
	handleSubmit = () => { },
	questions = [],
	editTaskData = {},
	staticObj = {},
	herokuRole = '',
	MsgText = '',
	type = '',
	recommenderList = [],
	dependentConfigsForVariousSteps = [],
	applicationDependentConfigs = [],
	relationshipOptions = [],
	relationshipType = 'text',
	relationshipFieldVisible = false,
	allowApplicantUploadsAfterDeadline = false,
	editRecommenderDetails= false,
	emailAuth,
	alretMsg = ()=> {},
	applicationId
}) => {
	const API = new KaleidoscopeAPI({});
	const [state, setState] = useState(staticObj);
	const [staticErrors, setStaticErrors] = useState([]);

	const [questionData, setQuestionData] = useState(questions);
	const [errors, setErrors] = useState([]);
	const [emailValidateError, setEmailValidateError] = useState("")

	let arr = JSON.parse(localStorage.getItem('hasAccessCode'))

	/**
	 * Handle modal field value change.
	 */

	 let userContext = cookies('context').context
	const handleChange = (name, value) => {
		setState(prevState => {
			return {
				...prevState,
				[name]: value
			}
		})
		if(staticErrors.includes(name)){
			let updatedErr = staticErrors.filter((err)=> err !== name)
			setStaticErrors(updatedErr)
		}
		if (name !== 'email') {
			staticValidation({ [name]: value }, value)
		}
		if (name == "email") {
			var emailValidError = validateEmailRegex(value)
				? ""
				: "Email is not valid!";


			if(value == emailAuth){
				let errArg = []
				emailValidError = "Use different Email Address"
				errArg.push("email")
				setStaticErrors(errArg)
			}
			setEmailValidateError(emailValidError)

		}
	}

	/**
	 * Handle modal field value change.
	 */
	const handleConfirmation = (name, value) => {
		let errorArg = []
		setState(prevState => {
			return {
				...prevState,
				[name]: value
			}
		})
		if (state.email !== value) {
			errorArg.push(name)
		} else {
			errorArg.pop('email_confirmation')
		}
		setStaticErrors(errorArg)
	}

	/**
	 * Handle Application answer value changes
	 */
	const onChange = (name, value, videoDuration = '') => {
		const isDocument = value?._response?.status===201
		const videodurationInSeconds = videoDuration || value?._response?.video_duration__c || ''
		// const isDocument = !!value.event
		const newState = [...questionData];
		let index = newState.findIndex(item => item.id === name);
		let question = newState.find(record => record.id === name)

		question = onChangeAnswer(question, value, isDocument, false, videodurationInSeconds)

		newState[index] = question
		setQuestionData(newState)
		let valid = applicantValidation(questionData, [], applicationDependentConfigs);
		setErrors(valid !== 'true' ? valid : [])
	}

	/**
	 * Handle task form submission.
	 */
	const submitTask = (e) => {
		e.preventDefault();
		var isValidEmail = false
		if (recommenderList.length > 0) {
			recommenderList.map((item) => {
				if (!state.id) {
					if (item.email === state.email) {
						isValidEmail = true
						toast.error(<FlashMessage message={`This email already exists.`} />)
					}
				} else if (item.id != state.id) {
					if (item.email === state.email) {
						isValidEmail = true
						toast.error(<FlashMessage message={`This email already exists.`} />)
					}
				}

			})
		}
		if(emailValidateError){
			let errArg = []
			errArg.push("email")
			setStaticErrors(errArg)
			// toast.error(<FlashMessage message={emailValidateError} />)
		}
		else if (blankSpaceCheck(state.first_name , state.last_name)) {
			toast.error(<FlashMessage type={'error'} message="You can't add spaces at the beginning of the first name or last name." />)
		  }else if (checkFieldLength(state.first_name, 40)) {
			toast.error(<FlashMessage type={'error'} message="First name must be less than 40 characters." />);
		  } else if (checkFieldLength(state.last_name, 80)) {
			toast.error(<FlashMessage type={'error'} message="Last name must be less than 80 characters." />);
		  }else if (checkFieldLength(state.email, 80)) {
			toast.error(<FlashMessage type={'error'} message="Email must be less than 80 characters." />);
		  }else{
			if (!isValidEmail && !emailValidateError) {
				relationshipFieldVisible && (state.relationship = (relationshipType === 'Picklist') ? state.relationship.value ? state.relationship.value : '' : state.relationship)
				if (!relationshipFieldVisible) {
					delete state.relationship
				}
				if (questionData.length > 0) {
					let valid = applicantValidation(questionData, dependentConfigsForVariousSteps, applicationDependentConfigs);
					let validStatic = staticValidation(state);
					setErrors(valid != 'true' ? valid : [])
					if (valid == 'true' && validStatic) {
						handleSubmit(state, questionData, type)
						setState(prevState => {
							return {
								...prevState,
								email_confirmation: ''
							}
						})
					} else {
						state.relationship = (relationshipType === 'Picklist') ? { label: state.relationship, value: state.relationship } : state.relationship
					}
				} else {
					if (staticValidation(state)) {
						handleSubmit(state, questionData, type)
						setState(prevState => {
							return {
								...prevState,
								email_confirmation: ''
							}
						})
					} else {
						state.relationship = (relationshipType === 'Picklist') ? { label: state.relationship, value: state.relationship } : state.relationship
					}
				}
			}
		  }
		
	}


	const staticValidation = (dataToCheck, val) => {
		let errorArg = []
		for (var key in dataToCheck) {
			if (key !== 'question_set_number' && key !== 'id') {
				if (dataToCheck && dataToCheck[key].length === 0) {
					errorArg.push(key)
				}
				if (dataToCheck && dataToCheck[key].length && val) {
					var tempArrErr = staticErrors || [];
					if(tempArrErr.length > 0){
						errorArg = tempArrErr.filter(obj => obj != key)
					}
				}
			}
		}
		if (dataToCheck.email !== dataToCheck.email_confirmation) {
			errorArg.push('email_confirmation')
		}
		if (!isEmpty(errorArg)) {
			setStaticErrors(errorArg)
			return false
		} else {
			return true
		}
	}


	const MultiLevelPiclistData = (fieldObj, answer) => {
		const picklistDep = (fieldObj.answer_options__c || '').split(/\r?\n/)
		let answerObj = {}
		picklistDep.map(val => {
			let values = val.split(';')
			const key = values.splice(0, 1)
			values = values.filter(val => !isEmpty(val))
			answerObj[key[0]] = values
			return null
		})

		let configVal = {
			title: answer ? answer : (fieldObj.answer ? fieldObj.answer : 'Choose your answer.')
		};

		const getSubMenu = (title) => {
			return {
				'title': title,
				'subMenu': null
			}
		}

		const configSubMenus = Object.keys(answerObj).map((key, index) => {
			const subMenus = answerObj[key].map(k => {
				return getSubMenu(k)
			})
			return {
				'title': key,
				'submenu': subMenus
			}
		})

		configVal['submenu'] = configSubMenus

		return [configVal]
	}

	// Answer dependent picklist
	const answerDependentOptions = (fieldObj, question) => {
		const parentVals = question.parent_picklist__c.split(';');
		const parentObj = questions.filter(obj => { return parentVals.includes(obj.name.toString()) })
		let answersToRemove = []
		parentObj.map(parent => {
			if (parent.answer) {
				const parentAns = parent.answer
				if (parent.data_type__c === "Multiselect Picklist") {
					const ansVal = Array.isArray(parentAns) ? parentAns : parentAns.split(';')
					ansVal.map(a => answersToRemove.push(a))
				} else {
					answersToRemove.push(parentAns)
				}
			}
			return null
		})


		const fieldOptions = (question.answer_options__c.split(';'))
		let finalOptions = []
		if (fieldOptions.length > 0) {
			fieldOptions.map(opt => {
				if (answersToRemove.indexOf(opt) < 0) {
					finalOptions.push(opt)
				}
				return null
			})
		}
		if ((question.answer) && (finalOptions.indexOf((question.answer)) < 0)) {
			onChange(question.id, "")
		}

		return formatAnswerOptions(finalOptions.join(";"))
	}

	// dependent picklist
	const dependentPicklistOptions = (question) => {
		const parentObj = questions.find(obj => { return obj.sfid.toString() === question.parent_picklist_config__c })
		if (parentObj) {
			const parentAns = (parentObj.answer)
			const picklistDep = question.picklist_dependency__c.split(/\r?\n/)
			let anserOptions = "";
			let options = [];
			if (parentObj.data_type__c === "Multiselect Picklist") {
				const ansVal = Array.isArray(parentAns) ? parentAns : (parentAns || '').split(';')
				let multipleOptions = [];
				ansVal.map((val) => {
					anserOptions = questions.find(obj => { return obj.sfid.toString() === question.parent_picklist_config__c })
					picklistDep.find(obj => { return obj.split(';')[0] === val }) || ''
					multipleOptions = multipleOptions.concat(anserOptions.split(';'))
					multipleOptions = multipleOptions.filter(function (el) {
						return !(el === val)
					});
					return null
				})
				options = uniq(multipleOptions);
			} else {
				anserOptions = picklistDep.find(obj => { return obj.split(';')[0] === parentAns }) || ''
				options = anserOptions.split(';')
				options = options.filter(function (el) {
					return !(el === parentAns)
				});
			}
			options = options.filter(function (el) {
				return !isEmpty(el)
			});
			return formatAnswerOptions(options.join(";"))
		} else {
			return []
		}
	}
	const handleShowError = (id) => {
		var message = ""
		if (errors.length > 0) {
			errors.map((item) => {
				if (id === item.id) {
					message = item.message
				}
			})
			return message
		}
		return null
	}

	const renderQuestionLabel = (label, required) => parseHtml(`${label?? ""} ${required ? '<span style="color:red">*</span>' : ''}`)

	/**
	 * Render Application Question Input
	 * @returns {JSX.Element}
	 */
	const renderApplicationInput = () => questions.map((question) => {
		return (
			<div className="application-section__question" key={question.sfid}>
				<Field
					id={question.id}
					label={renderQuestionLabel(question.label__c, question.required__c)}
					ariaLabel={question.label__c}
					labelClassName="application-field-label-style"
					className="application-section__textarea"
					minNumber={question.exit_config__c ? '' : question.min_value__c}
					maxNumber={question.exit_config__c ? '' : question.max_value__c}
					minDateFilter={question.exit_config__c ? '' : question.min_date__c}
					maxDateFilter={question.exit_config__c ? '' : question.max_date__c}
					dateFormat={question.data_type__c}
					multiLevelQeustion={question}
					multiLevelPiclistData={() => MultiLevelPiclistData(question, question.answer)}
					description={parseHtml(question.description__c)}
					errorMsg={handleShowError(question.id)}
					placeholder='Please answer.'
					type={getQuestionDataType(question.data_type__c)}
					value={getAnswer(question)}
					required={question.is_required__c || question.word_count_enable__c}
					uploadedDocument={getDocumentObject(question)}
					has_document={question.doc_upload_required__c}
					handleChange={(value, videoDuration) => onChange(question.id, value, videoDuration)}
					handleDocumentDelete={(documentName) => deleteAzureDocument(documentName)}
					removeCrossIcon={(type === 'edit') ? true : false}
					videoLable={findLabel(question)}
					options={question.data_type__c === "Answer Dependent Picklist" ? answerDependentOptions(question.answer_options__c || '', question) : question.data_type__c === "Dependent Picklist" ? dependentPicklistOptions(question) : formatAnswerOptions(question.answer_options__c || '', question.data_type__c)}
					minWordCountLongText={question.minimum_word_required__c ? question.minimum_word_required__c : false}
					// charLimit={question.word_count_enable__c && question.data_type__c === 'Long Text' ? question.word_count__c : question.word_count__c || 2500}
					charLimit={question.data_type__c === 'Long Text' ?  question.word_count_enable__c ? question.word_count__c ? question.word_count__c : 25000 : 25000  : 255}
					disabled={type === 'view rec' || (arr?.includes(applicationId) ? false : allowApplicantUploadsAfterDeadline ? allowApplicantUploadsAfterDeadlineFunction(getQuestionDataType(question.data_type__c)) : false)}
					allowApplicantUploadsAfterDeadline={allowApplicantUploadsAfterDeadline && question.doc_upload_required__c || false}
					onHandleBlur={(value) => onChange(question.id, value)}
					allowHtmlTags={["Long Text", "Text"].includes(question.data_type__c)}
					isAzureDelete={true}
				/>
			</div>
		)
	})

	const updateQuestionData = async (docName) => {
		const cloneData = cloneDeep(questionData)
		const updatedData = cloneData.map((fieldData) => {
			const data = fieldData?.document?.doc_url__c
			const uploadedFileName = data?.split("/")[6];
			const fileName = uploadedFileName?.split("?").shift();
		  if (fileName === docName) {
			// Use destructuring to remove the `document` property
			return { ...fieldData, document: {} };
		  }
		  return fieldData;
		});
		setQuestionData(updatedData)
	  }

	/**
	 * Delete Application Question Document
	 * @returns {JSX.Element}
	 */
	const deleteAzureDocument = async (documentName) => {
		let uploadedFileName = documentName?.split("/")[6];
		let fileName = uploadedFileName?.split("?").shift();
		const deleteResponse = await API.deleteAzureDocument(fileName)
		if (deleteResponse?._response?.status === 202) {
			updateQuestionData(fileName);
			toast.success(<FlashMessage message='Document deleted successfully.' />);
		  } else {
			toast.error(<FlashMessage type="error" message='Something went wrong, please try again.' />);
		}
		}

const handleModalClose=()=>{
	if(userContext === MAIN_CONFIG.USER_CONTEXT.APPLICANT && type !== 'view rec'){
		alretMsg() 	
	}
	else{
		handleClose()
	}
}
	return (
		<div id='task-modal-content'>
			<div className="task-head_container">
				<div className="H1DesktopGreen event-head">{ userContext === "DONOR" && type === "edit" && "Update"} {(herokuRole === 'School Counsellor' ? 'School Counselor' : herokuRole) }</div>
				<a role='button' onKeyDown={(e)=>handleKeyDown(e,()=>handleModalClose())} tabIndex={0} aria-label='close modal' className="event-head H6DesktopGrey cancel-icon" onClick={handleModalClose}>X</a>
			</div>

			<div className="user-task-add__form">
				<form onSubmit={(e) => submitTask(e)}>

					<div className="application-section__question">
						{/* <label className="application-section__label">
							First Name
						</label> */}
						<Field
							label={renderQuestionLabel("First Name", true)}
							ariaLabel="First Name"
							labelClassName="application-field-label-style"
							className="application-section__textarea"
							placeholder='First Name'
							name='first_name'
							type='text'
							value={state.first_name}
							handleChange={(value) => handleChange('first_name', value)}
							errorMsg={staticErrors.includes('first_name') && "Please enter your reference's first name."}
							disabled={type === 'view rec' || (arr?.includes(applicationId) ? false : allowApplicantUploadsAfterDeadline ? allowApplicantUploadsAfterDeadlineFunction('text') : false)}
						/>
					</div>
					<div className="application-section__question">
						{/* <label className="application-section__label">
							Last Name
						</label> */}
						<Field
							label={renderQuestionLabel("Last Name", true)}
							ariaLabel="Last Name"
							labelClassName="application-field-label-style"
							className="application-section__textarea"
							placeholder='Last Name'
							name='last_name'
							type='text'
							value={state.last_name}
							handleChange={(value) => handleChange('last_name', value)}
							required={true}
							errorMsg={staticErrors.includes('last_name') && "Please enter your reference's last name."}
							disabled={type === 'view rec' || (arr?.includes(applicationId) ? false : allowApplicantUploadsAfterDeadline ? allowApplicantUploadsAfterDeadlineFunction('text') : false)}
						/>
					</div>
					<div className="application-section__question">
						{/* <label className="application-section__label">
							Email
						</label> */}
						<Field
							label={renderQuestionLabel("Email", true)}
							ariaLabel="Email"
							labelClassName="application-field-label-style"
							className="application-section__textarea"
							placeholder='Email'
							name='email'
							type='email'
							value={state.email}
							required={true}
							handleChange={(value) => handleChange('email', value)}
							errorMsg={staticErrors.includes('email') && "Email address must be different than the email on your account."}
							disabled={type === 'view rec' || (arr?.includes(applicationId) ? false : allowApplicantUploadsAfterDeadline ? allowApplicantUploadsAfterDeadlineFunction('text') : false)}
						/>
					</div>
					{type !== 'view rec' && <div className="application-section__question">
						<Field
							label={renderQuestionLabel("Email Confirmation", true)}
							ariaLabel="Email Confirmation"
							labelClassName="application-field-label-style"
							className="application-section__textarea"
							placeholder='Email Confirmation'
							name='email_confirmation'
							type='email'
							value={state.email_confirmation || ''}
							isPaste={true}
							handleChange={(value) => handleConfirmation('email_confirmation', value)}
							required={true}
							errorMsg={staticErrors.includes('email_confirmation') && "Please confirm your email address."}
						/>
					</div>}
					{relationshipFieldVisible &&
						<div className="application-section__question">
							{/* <label className="application-section__label">
								Relationship
							</label> */}
							{relationshipType === 'Picklist' ?
								<Field
									label={renderQuestionLabel("Relationship", true)}
									ariaLabel="Relationship"
									labelClassName="application-field-label-style"
									className="application-section__textarea"
									type='select'
									value={state.relationship}
									options={CreateOptions(relationshipOptions.split(';'))}
									placeholder='Select Relationship'
									handleChange={(val) => handleChange('relationship', val)}
									// createPicklist={true}
									required={true}
									errorMsg={staticErrors.includes('relationship') && "Please enter your relationship to your reference."}
									disabled={type === 'view rec' || (arr?.includes(applicationId) ? false : allowApplicantUploadsAfterDeadline ? allowApplicantUploadsAfterDeadlineFunction('text') : false)}
								/>
								:
								<Field
									label={renderQuestionLabel("Relationship", true)}
									ariaLabel="Relationship"
									labelClassName="application-field-label-style"
									className="application-section__textarea"
									placeholder='Relationship'
									name='relationship'
									type='text'
									required={true}
									value={state.relationship}
									handleChange={(value) => handleChange('relationship', value)}
									errorMsg={staticErrors.includes('relationship') && "Please enter your relationship to your reference."}
									disabled={type === 'view rec' || (arr?.includes(applicationId) ? false : allowApplicantUploadsAfterDeadline ? allowApplicantUploadsAfterDeadlineFunction('text') : false)}
								/>
							}
						</div>
					}

					{renderApplicationInput()}

					<div className="application-section__question">
						{/* <label className="application-section__label">
							Message
						</label> */}
						<Field
							label={renderQuestionLabel("Message", true)}
							ariaLabel="Message"
							labelClassName="application-field-label-style"
							className="application-section__textarea"
							placeholder='Message'
							name='message'
							type='textarea'
							value={state.message}
							required={true}
							handleChange={(value) => handleChange('message', value)}
							errorMsg={staticErrors.includes('message') && "Please enter a message to your reference."}
							charLimit={2500}
							disabled={type === 'view rec' || (arr?.includes(applicationId) ? false : allowApplicantUploadsAfterDeadline ? allowApplicantUploadsAfterDeadlineFunction('text') : false)}
						/>

					</div>
					{type !== 'view rec' && <div className="message-for-recommender">
						{MsgText}
					</div>}


					{type !== 'view rec' && <div className="applicant-task__submit">
						<CTAButton id='recommender-page__cta__cancel' type='inverse' outline onClick={handleClose} buttonType = "button">
							Cancel
						</CTAButton>

						<CTAButton id='recommender-page__cta' type='tertiary'>
							Submit
						</CTAButton>
					</div>
					}
				</form>
			</div>
		</div>
	)
}